import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import Swiper from 'swiper';
import {remRate} from './utils.js';

export default function() {
    (function makePromoSlider() {
        const promoSlider = document.querySelector('.promo');
        if(!promoSlider){return;}
        const slider = new Swiper('.promo__slider', {
            speed: 100,
            effect: 'fade',
            fadeEffect: {
                crossFade: true
            },
            loop: true,
            navigation: {
                prevEl: '.promo__nav--prev',
                nextEl: '.promo__nav--next',
           }
        });
    })();

    (function makeOtherSlider() {
        const promoSlider = document.querySelector('.other-slider');
        if(!promoSlider){return;}
        const slider = new Swiper('.other-slider__container', {
            speed: 400,
            loop: true,
            slidesPerView: 3,
            spaceBetween: 60,
            centeredSlides: true,
            navigation: {
                prevEl: '.other-slider__nav--prev',
                nextEl: '.other-slider__nav--next',
            }
        });
    })();

    (function initBasicSliders() {
        const sliders = document.querySelectorAll('.slider');
        if (sliders.length == 0) {return;}

        sliders.forEach(function(elem) {
            const container = elem.querySelector('.slider__container');
            const prevArrow = elem.querySelector('.slider__nav--prev');
            const nextArrow = elem.querySelector('.slider__nav--next');
            if (elem.classList.contains('slider--mini')) {
                let slider = new Swiper(container, {
                    speed: 500,
                    slidesPerView: 'auto',
                    centeredSlides: true,
                    spaceBetween: 40 * remRate,
                    loop: true,
                    navigation: {
                        prevEl: prevArrow,
                        nextEl: nextArrow
                    }
                })
            } else {
                let slider = new Swiper(container, {
                    speed: 500,
                    slidesPerView: 'auto',
                    centeredSlides: true,
                    spaceBetween: 40 * remRate,
                    loop: true,
                    navigation: {
                        prevEl: prevArrow,
                        nextEl: nextArrow
                    }
                })
            }
        });
    })();
}
