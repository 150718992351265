import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import 'lightgallery.js';
import Swiper from 'swiper';
import {mql} from "./utils";
import {remRate} from "./utils";

export default function() {
    (function makeHeaderDropdown() {
        const header = document.querySelector('.header');
        if (!header) {return;}
        const items = header.querySelectorAll('.header__item');
        items.forEach((element) =>{
           let dropZone = element.querySelector('.header__dropdown-list');

           if (dropZone) {
               element.addEventListener('mouseover', () => {
                   element.classList.add('header__item--triggered');
               });
               element.addEventListener('mouseleave', () => {
                   element.classList.remove('header__item--triggered');
               });
           }
        });
    })();
    (function makeSearchToggle() {
        const searchContainer = document.querySelector('.header__item--search');
        if (!searchContainer) {return;}
        const searchToggleButton = document.querySelector('.header__search-button');
        searchToggleButton.addEventListener('click', function(evt) {
            evt.preventDefault();
            searchToggleButton.classList.toggle('header__search-button--triggered');
            searchContainer.classList.toggle('header__item--search-triggered');
        })
    })();

    (function makeProductGallery() {
        const productCard = document.querySelector('.product__card');
        if (!productCard) {return;}
        lightGallery(productCard);
    })();

    (function makeGalleryItems() {
        const gallery = document.querySelector('.gallery__list');
        if (!gallery) {return;}
        lightGallery(gallery);
    })();

    (function makeCertGallery() {
        const gallery = document.querySelector('.about__cert-list');
        if (!gallery) {return;}
        lightGallery(gallery);
    })();

    (function makeParamsDropdownLists() {
        const params = document.querySelectorAll('.params');
        if (params.length == 0) {return;}
        params.forEach((elem) => {
            let list = elem.querySelector('.params__list');
            let items = list.querySelectorAll('.params__item');
            items.forEach((el) => {
                let pushZone = el.querySelector('.params__push-zone');
                let dropZone = el.querySelector('.params__drop-zone');
                let height = el.querySelector('.params__table-container').offsetHeight;
                pushZone.addEventListener('click', (evt) =>{
                    evt.preventDefault();
                    el.classList.toggle('params__item--triggered');

                    if (el.classList.contains('params__item--triggered')) {
                        dropZone.style.maxHeight = height + 'px';
                    } else {
                        dropZone.style.maxHeight = 0;
                    }

                })
            });

        })
    })();

}
